import React from 'react'
import { HubStyleObject } from '@hub/design-system-base'
import { Text } from '@hub/text'

interface NotificationProps {
  /** @deprecated Please use sx instead **/
  className?: string
  message?: string
  sx?: HubStyleObject
}

const EventNotification: React.FunctionComponent<
  React.PropsWithChildren<NotificationProps>
> = ({ className, message = '*Upcoming events are subject to change', sx }) => (
  <Text as="p" sx={{ fontSize: 'font-body', ...sx }} className={className}>
    {message}
  </Text>
)

export default EventNotification

import React from 'react'
import classNames from 'classnames'
import {
  cloudinaryImageUrl,
  getImageHeight,
} from '@scentregroup/ui/tools/cloudinary/image'
import LazyLoad from 'react-lazyload'
import style from '../responsive-asset/index.module.scss'

export type AngleOrigin =
  | 'bottom-left'
  | 'top-left'
  | 'top-right'
  | 'bottom-right'
type RatioOption = 'square' | 'standard' | 'poster' | 'feature'
type BackgroundSize = 'cover' | 'contain'

const DEFAULT_DESKTOP_BREAKPOINT = 641

interface ResponsiveImageProps {
  className?: string
  desktopImageUrl?: string
  mobileImageUrl?: string
  mobileRatio?: RatioOption
  desktopRatio?: RatioOption
  numberOfItems?: number
  alt?: string
  lazyLoad?: boolean
  bgSize?: BackgroundSize
  onLoad?: (e?: React.SyntheticEvent<HTMLImageElement>) => void
  onError?: (e: React.ChangeEvent<HTMLImageElement>) => void
  width?: number
  dataTestId?: string
  desktopBreakpoint?: number
}

class ResponsiveImage extends React.Component<ResponsiveImageProps> {
  public static defaultProps = {
    alt: '',
    lazyLoad: true,
  }

  public componentDidMount(): void {
    // Make sure onLoad is still fired if the image was already loaded via SSR
    if (this.imageNode.current && this.imageNode.current.complete) {
      this.onLoad()
    }
  }

  private imageNode: React.RefObject<HTMLImageElement> = React.createRef()

  private onLoad = (event?: React.SyntheticEvent<HTMLImageElement>): void => {
    if (this.props.onLoad) {
      this.props.onLoad(event)
    }
  }

  private onError = (event: React.ChangeEvent<HTMLImageElement>): void => {
    if (this.props.onError) {
      this.props.onError(event)
    }
  }

  public render(): JSX.Element {
    const {
      className,
      desktopImageUrl,
      mobileImageUrl,
      mobileRatio,
      desktopRatio,
      desktopBreakpoint = DEFAULT_DESKTOP_BREAKPOINT,
      numberOfItems = 1,
      alt,
      lazyLoad,
      bgSize,
      width,
      dataTestId,
    } = this.props
    const placeholderElement = (
      <div className={className}>
        <div
          className={classNames(
            style.placeholder,
            style[`desktopRatio_${desktopRatio}`],
            style[`mobileRatio_${mobileRatio}`]
          )}
        />
      </div>
    )

    // both desktop annd mobile urls are mandatory and have to be passed
    if (!desktopImageUrl || !mobileImageUrl) {
      return placeholderElement
    }

    const pictureContent = (
      <picture
        className={classNames(
          style.ResponsiveAsset,
          className,
          style[`desktopRatio_${desktopRatio}`],
          style[`mobileRatio_${mobileRatio}`]
        )}
        data-test-id={dataTestId}
      >
        <source
          media={`(max-width: ${desktopBreakpoint - 1}px)`}
          srcSet={cloudinaryImageUrl({
            src: mobileImageUrl,
            ratio: mobileRatio,
            type: 'mobile',
            numberOfItems,
            bgSize,
            width,
          })}
        />
        <source
          media={`(min-width: ${desktopBreakpoint}px)`}
          srcSet={cloudinaryImageUrl({
            src: desktopImageUrl,
            ratio: desktopRatio,
            type: 'default',
            numberOfItems,
            bgSize,
            width,
          })}
        />
        <img
          className={style.ResponsiveAsset}
          ref={this.imageNode}
          src={cloudinaryImageUrl({
            src: desktopImageUrl,
            ratio: desktopRatio,
            type: 'default',
            numberOfItems,
            bgSize,
            width,
          })}
          alt={alt}
          onLoad={this.onLoad}
          onError={this.onError}
        />
      </picture>
    )

    return lazyLoad ? (
      <LazyLoad
        offset={800}
        height={getImageHeight({
          ratio: mobileRatio,
          type: 'default',
          numberOfItems,
        })}
      >
        {pictureContent}
      </LazyLoad>
    ) : (
      pictureContent
    )
  }
}

export default ResponsiveImage

/* eslint-disable jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events */
import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import trackClickEvent from '../../helpers/analytics-utils/track-event'
import style from './index.module.scss'

const ExternalLink: React.FC<React.PropsWithChildren<ExternalLinkProps>> = ({
  url,
  className,
  eventCategory,
  eventAction,
  eventLabel,
  children,
  target = '_blank',
  rel = 'noopener noreferrer',
  ...otherProps
}): JSX.Element => {
  const props = {
    href: url,
    target,
    rel,
    className: classNames(className, { [style.ExternalLink]: !className }),
    ...otherProps,
  }
  return (
    <a
      onClick={
        eventCategory
          ? () => trackClickEvent(eventCategory, eventAction, eventLabel)
          : undefined
      }
      {...props}
    >
      {children}
    </a>
  )
}

interface ExternalLinkProps {
  url: string
  className?: string
  eventCategory?: string
  eventAction?: string
  eventLabel?: string
  children?: React.ReactNode
  target?: string
  rel?: string
  otherProps?: unknown
  onClick?: (event: React.MouseEvent<HTMLAnchorElement>) => void
}

ExternalLink.propTypes = {
  url: PropTypes.string.isRequired,
  className: PropTypes.string,
  eventCategory: PropTypes.string,
  eventAction: PropTypes.string,
  eventLabel: PropTypes.string,
  children: PropTypes.node,
  target: PropTypes.string,
  rel: PropTypes.string,
}

export default ExternalLink

import { trustedHostnameSuffixes } from '../constants'

export const parseHostname = urlString => {
  try {
    const url = new URL(urlString)
    return url.hostname
  } catch {
    return null
  }
}

export const parsePathname = urlString => {
  try {
    const url = new URL(urlString)
    return url.pathname
  } catch {
    return null
  }
}

export const parseUrl = urlString => {
  try {
    const url = new URL(urlString)
    return url
  } catch {
    return null
  }
}

export const withoutProtocol = urlString => {
  try {
    const url = new URL(urlString)
    return url.href.slice(url.protocol.length)
  } catch {
    return null
  }
}

export function absoluteUrl(relativeUrl, baseUrl) {
  const url = new URL(relativeUrl, baseUrl)
  return url.href
}

export function isAbsolute(url) {
  const r = new RegExp('^(?:[a-z]+:)?//', 'i')
  return r.test(url)
}

export function isTrustedUrl(url) {
  // Relative URLs are implicitly trusted. This to support
  // relative URLs in featured links
  if (!isAbsolute(url)) {
    return url
  }
  const hostname = parseHostname(url)
  const isTrusted = trustedHostnameSuffixes.some(suffix =>
    hostname.endsWith(suffix)
  )
  return isTrusted ? url : ''
}

import { DateTime, isBeforeNow } from '../date-helpers'
import {
  formatTime,
  formatHumanDate,
  formatMonth,
  formatYear,
  formatDayNumber,
} from '../date-format-helpers'
import getCalendarInvite from '../get-calendar-invite'
import { AVAILABLE_CALENDARS } from '../../constants'

const getCals = (entry, startAt, endAt) => {
  if (Boolean(startAt) && Boolean(endAt)) {
    return AVAILABLE_CALENDARS.reduce(
      (acc, cal) => ({
        ...acc,
        [cal]: getCalendarInvite[cal](entry, startAt, endAt),
      }),
      {}
    )
  }
  return null
}

const validateOccurrences = (occurrences, pastEvents = false) =>
  occurrences.filter(occurrence =>
    DateTime.fromISO(occurrence.startAt) < DateTime.fromISO(occurrence.endAt) &&
    pastEvents
      ? isBeforeNow(occurrence.endAt)
      : !isBeforeNow(occurrence.endAt)
  )

export const getOccurrences = (isSummary, entry, timeZone, inclCalendar) => {
  if (!entry) {
    return null
  }

  const { startAt, endAt, hideAt, occurrences } = entry
  const end = endAt || hideAt

  if (!occurrences && !end && !startAt) {
    return null
  }

  const normalisedOccurrences =
    occurrences && occurrences.length
      ? [...occurrences]
      : [
          {
            startAt,
            endAt: end,
          },
        ]

  const currentTimes = validateOccurrences(normalisedOccurrences)
  const pastTimes = validateOccurrences(normalisedOccurrences, true)
  const result = { summary: isSummary, times: currentTimes, timeZone }
  if (isSummary) {
    return result
  } else {
    return {
      ...result,
      pastTimes,
      times: currentTimes.map(x => ({
        ...x,
        calendar: inclCalendar && getCals(entry, x.startAt, x.endAt),
      })),
    }
  }
}

export const isExpired = (entry, timeZone) => {
  if (!entry) {
    return null
  }

  const { hideAt } = entry
  if (!hideAt) {
    return false
  }
  return isBeforeNow(hideAt, timeZone)
}

export const firstOccurrence = (occurrences, timeZone) =>
  occurrences.reduce(
    (acc, cur) => {
      const dateTime = DateTime.fromISO(cur.startAt, { timeZone })
      return acc < dateTime ? acc : dateTime
    },
    DateTime.fromISO(occurrences[0]['startAt'], { timeZone })
  )

export const lastOccurrence = (occurrences, timeZone) =>
  occurrences.reduce(
    (acc, cur) => {
      const dateTime = DateTime.fromISO(cur.endAt, { timeZone })
      return acc > dateTime ? acc : dateTime
    },
    DateTime.fromISO(occurrences[0]['endAt'], { timeZone })
  )

export const summariseOccurrences = (occurrences, timeZone = 'Zulu') => {
  const startDate = firstOccurrence(occurrences, timeZone)
  const endDate = lastOccurrence(occurrences, timeZone)
  // if it runs into two calendar years then we should the years as well
  const includeYear = endDate.year() - startDate.year() > 0
  if (
    occurrences.length === 1 &&
    startDate.dayOfYear() === endDate.dayOfYear()
  ) {
    return `${formatHumanDate(startDate, {
      includeMonth: true,
      includeYear,
    })}, ${formatTime(startDate)} - ${formatTime(endDate)}`
  } else if (startDate.dayOfYear() === endDate.dayOfYear()) {
    return `${formatHumanDate(startDate, { includeMonth: true, includeYear })}`
  } else {
    return includeYear
      ? `${formatDayNumber(startDate)} ${formatMonth(startDate)} ${formatYear(
          startDate
        )} - ${formatDayNumber(endDate)} ${formatMonth(endDate)} ${formatYear(
          endDate
        )}`
      : `${formatDayNumber(startDate)} ${formatMonth(
          startDate
        )} - ${formatDayNumber(endDate)} ${formatMonth(endDate)}`
  }
}

"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ratioOptions = exports.getImageHeight = exports.default = exports.cloudinaryImageUrl = exports.bgMode = exports.URL_PREFIX = exports.URL_EXCLUDE = exports.OLD_CLOUDINARY_URL = exports.CLOUDINARY_URL = void 0;
/* eslint-disable no-magic-numbers */
const OLD_CLOUDINARY_URL = exports.OLD_CLOUDINARY_URL = 'https://res.cloudinary.com';
const CLOUDINARY_URL = exports.CLOUDINARY_URL = 'https://images.scentregroup.io';
const URL_PREFIX = exports.URL_PREFIX = `${CLOUDINARY_URL}/image/fetch/`;
const URL_EXCLUDE = exports.URL_EXCLUDE = [OLD_CLOUDINARY_URL, CLOUDINARY_URL, '/shop/_next', '/account-info/_next', '/static', '/_next'];
const bgMode = exports.bgMode = {
  cover: 'c_fill,q_auto,g_faces:auto',
  contain: 'c_pad,b_auto',
  stretch: ''
};
const ratios = {
  square: 1,
  standard: Number(2 / 3),
  poster: Number(3 / 2),
  feature: Number(9 / 21)
};
const ratioOptions = exports.ratioOptions = Object.keys(ratios);
const calcContainerWidth = _ref => {
  let {
    type,
    ratio
  } = _ref;
  // get special images widths for mobile and features
  if (type === 'mobile') {
    return 640;
  }
  if (ratio === 'feature') {
    return 2500;
  }
  return 18 * 4 * 16;
};
const getRatioWidthHeight = (type, ratio, numberOfItems, minWidth) => {
  const containerWidth = calcContainerWidth({
    type,
    ratio
  });
  const imageRatio = ratios[ratio] || ratios.standard;
  let width = Math.floor(containerWidth / numberOfItems);
  width = minWidth ? Math.max(width, minWidth) : width;
  const height = Math.floor(width * imageRatio);
  return {
    width,
    height
  };
};
const getImageHeight = _ref2 => {
  let {
    type,
    ratio,
    numberOfItems
  } = _ref2;
  let height = 0;
  if (type || ratio) {
    height = getRatioWidthHeight(type, ratio, numberOfItems).height;
  }
  return height;
};
exports.getImageHeight = getImageHeight;
const cloudinaryImageUrl = _ref3 => {
  let {
    src,
    type,
    ratio,
    numberOfItems = 1,
    bgSize,
    minWidth = 600,
    width,
    autoFormat = true
  } = _ref3;
  if (!src || URL_EXCLUDE.some(val => src.startsWith(val))) {
    return src;
  } else {
    let url = src;
    const cropOptions = [bgMode[bgSize] || bgMode.cover];
    if (width) {
      cropOptions.push(`w_${width}`);
    } else if (type || ratio) {
      const size = getRatioWidthHeight(type, ratio, numberOfItems, minWidth);
      cropOptions.push(`w_${size.width},h_${size.height}`);
    }
    if (autoFormat) {
      cropOptions.push('f_auto');
    }
    if (src.indexOf('//') === 0) {
      url = `https:${src}`;
    }
    return `${URL_PREFIX}${cropOptions.join(',')}/${url}`;
  }
};
exports.cloudinaryImageUrl = cloudinaryImageUrl;
var _default = exports.default = cloudinaryImageUrl;